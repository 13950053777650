import React, { useContext, useEffect, useState } from "react";
import { FaTimes } from "react-icons/fa";
import { TextShimmer } from "../shimmer/shimmer";
import { FaSearch } from "react-icons/fa";
import { Button, Checkbox, Select, Tooltip } from "antd";
import { ButtonSpinner } from "../spinners";
import {
  ChangeWorkerStatusOverride,
  ChangeWorkerStatusRoster,
  GetWorkersType,
} from "../../helper/worksite/worksite";
import Worksite from "../../pages/Admin/Worksite/Worksite";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import { GetPayrollCatagoryId } from "../../helper/setup/payroll/payrollcatagory";
import {
  assignWorkerPayroll,
  assignWorkerPayrollCat,
  assignWorkerPayrollData,
  assignWorkerPayrollmultiple,
  assignWorkerPayrollmultipleWork,
} from "../../helper/worksite/worksiteDetails.js/areas";
import { AppContext } from "../../layouts/Main";

const MultySelectTable = ({
  data,
  setData,
  search,
  worksite,
  setWorksite,
  setWorkerLength,
}) => {
  console.log("the data os", data);
  const [dataStore, setDataStore] = useContext(AppContext);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedWorker, setSelectedWorker] = useState();
  const [inductionPending, setInductionPending] = useState(false);
  const [isLoadingOverride, setIsLoadingOverride] = useState(false);
  const [payrollpending, setPayrollPending] = useState(false);
  const [workDayPending, setWorkDayPending] = useState(false);
  const [empStatePending, setEmpDayPending] = useState(false);
  const [payrollData, setPayrollData] = useState();
  console.log("the opayrolldata ", payrollData);
  const [payrollModal, setPayrollModal] = useState(false);
  const [selectedTask, setSelectedTask] = useState([]);
  const [selectedID, setSelectedID] = useState();
  const [selectedWorkerItem, setSelectedWorkerItem] = useState();
  const [selectedWorkerId, setSelectedWorkerId] = useState();
  const [assignedWorker, setAssignedWorker] = useState([]);
  const [payrollDataArr, setPayrollDataArr] = useState([]);
  const [selectedData, setSelectedData] = useState({ workerData: [] });
  const [empStatus, setEmpStatus] = useState(false);
  const [payrollPendingData, setPayrollPendingData] = useState(false);

  const id = useParams();
  const closeModal = () => {
    setData({
      ...data,
      isMultySelect: false,
      isShimmer: true,
      options: [],
    });
  };

  const handleMultySelect = {
    check: (e, id) => {
      if (e?.target?.checked) {
        let allselectedOption = [...data?.selectedOption];
        allselectedOption?.push(id);

        setData({
          ...data,
          selectedOption: allselectedOption,
        });
      } else {
        let allselectedOption = [...data?.selectedOption];
        allselectedOption?.splice(allselectedOption?.indexOf(id), 1);

        setData({
          ...data,
          selectedOption: allselectedOption,
        });
      }
    },
  };
  const addWorker = async () => {
    setIsLoading(true);
    let response = await ChangeWorkerStatusRoster(
      data?.selectedOption,
      Worksite,
      id
    );
    if (response?.status == true) {
      setIsLoading(false);
      setData(false);
      toast.success("Add Worker successfully");
      const param = { type: "Rostered", id: id?.id };
      const res = await GetWorkersType(param);
      if (res?.status) {
        setWorksite({
          ...worksite,
          data: res?.data?.data,
        });
        setWorkerLength(res?.data?.data?.length);
      }
    } else {
      if (response?.status_code == "IND_PEN") {
        setSelectedWorkerItem(response?.data?.userData);
        setInductionPending(true);
        setIsLoading(false);
      }
      if (response?.status_code == "PAYR_PEN") {
        setSelectedWorkerItem(response?.data?.userData);
        setPayrollPending(true);
        setIsLoading(false);
      }
      if (response?.status_code == "WORK_PAY_PENDING") {
        setSelectedWorkerItem(response?.data?.userData);
        fetchPayrollData();
        setWorkDayPending(true);
        setIsLoading(false);
      }
      if (response?.status_code == "EMP_STAT_PENDING") {
        setSelectedWorkerItem(response?.data?.userData);
        fetchPayrollData();
        setEmpDayPending(true);
        const data = { worker_id: "", payroll_ids: "" };
        let userData = response?.data?.userData?.length;
        for (let n = userData; n > 0; --n) {
          selectedData?.workerData.push(data);
        }
        setSelectedData({ ...selectedData });
        setIsLoading(false);
      }
      if (response?.status_code == "WORK_EMP_STAT_PENDING") {
        setSelectedWorkerItem(response?.data?.userData);
        fetchPayrollData(response?.data?.employment_status_id);
        setEmpStatus(true);
      }
      if (response?.status_code == "WORKER_PAYRO_PEND") {
        setSelectedWorkerItem(response?.data?.userData);
        fetchPayrollData();
        setPayrollPendingData(true);
        const data = { worker_id: "", payroll_ids: "" };
        let userData = response?.data?.userData?.length;
        for (let n = userData; n > 0; --n) {
          selectedData?.workerData.push(data);
        }
        setSelectedData({ ...selectedData });
        setIsLoading(false);
      }
      // else {
      //   toast?.error(response?.message)
      //   setIsLoading(false)
      // }
    }
  };
  const fetchPayrollData = async (workerId) => {
    let res = await GetPayrollCatagoryId(workerId ? workerId : "");
    if (res?.status) {
      setPayrollData({ ...payrollData, data: res?.data?.data });
    }
  };
  const handleChange = (value) => {
    setSelectedID(value);
  };
  const overRideWorker = async () => {
    setIsLoadingOverride(true);
    let response = await ChangeWorkerStatusOverride(
      data?.selectedOption,
      Worksite,
      id
    );
    if (response?.status) {
      setIsLoadingOverride(false);
      setData(false);
      toast.success("Add Worker successfully");
      const param = { type: "Rostered", id: id?.id };
      const res = await GetWorkersType(param);
      if (res?.status) {
        setWorksite({
          ...worksite,
          data: res?.data?.data,
        });
        setWorkerLength(res?.data?.data?.length);
      }
    }
  };
  const openPayrollModal = (workerId) => {
    setPayrollModal(true);
    fetchPayrollData(workerId);
  };
  const closePayrollModal = () => {
    setPayrollModal(false);
  };
  const checkPayroll = (e, item) => {
    if (e?.target?.checked) {
      // setSelectedTask([item?.id]);
      setSelectedTask([...selectedTask, item?.id]);
    } else {
      const updatedTaskArr = selectedTask.filter(
        (taskId) => taskId !== item?.id
      );
      setSelectedTask(updatedTaskArr);
    }
  };
  const assignPayroll = async () => {
    setAssignedWorker([...assignedWorker, selectedWorkerId]);
    // let res = await assignWorkerPayroll(selectedTask, data?.selectedOption)
    let res = await assignWorkerPayrollmultipleWork(selectedTask, worksite);
    if (res?.status) {
      toast.success(res?.message);
      closePayrollModal();
      // setEmpDayPending(false)
      // setPayrollPending(false)
      // closeModal()
      setEmpStatus(false);
      addWorker();
    }
  };
  const assignWorkerRate = async () => {
    let res = await assignWorkerPayrollCat(selectedID, id);
    if (res?.status) {
      toast?.success(res?.message);
      setWorkDayPending(false);
    }
  };
  useEffect(() => {
    (async () => {
      await search();
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleChangePayrollArr = (value, index, work_id) => {
    let allPrev = [...selectedData?.workerData];
    let updatedElement = { ...allPrev[index] };
    updatedElement.payroll_ids = [value];
    updatedElement.worker_id = work_id;
    allPrev[index] = updatedElement;
    setSelectedData({ ...selectedData, workerData: allPrev });
  };
  const openPayrollCategory = async (empId, workerId) => {
    const res = await GetPayrollCatagoryId(empId, workerId);
    if (res?.status) {
      setPayrollDataArr(res?.data?.data);
    }
  };
  const assignPayrollWorker = async () => {
    let res = await assignWorkerPayrollmultiple(selectedData, worksite);
    if (res?.status) {
      toast?.success(res?.message);
      setEmpDayPending(false);
      addWorker();
    }
  };
  const assignPayrollWorkerPayroll = async () => {
    let res = await assignWorkerPayrollData(selectedData, worksite?.id);
    if (res?.status) {
      toast?.success(res?.message);
      setPayrollPendingData(false);
      addWorker();
    }
  };
  return (
    <>
      <div className="modal">
        <div className="modal-content">
          <div className="text-right">
            <button className="close-modal" onClick={closeModal}>
              <FaTimes />
            </button>
          </div>

          <div className="modal-body mt-2">
            {data?.isShimmer ? (
              <>
                <TextShimmer data={{ gap: 15, line: 20 }} />
              </>
            ) : (
              <>
                <h2 className="text-xl font-bold">{data?.title}</h2>

                <div className="mt-5">
                  <div className="mb-5">
                    <div className="border border-[#111111] flex items-center p-1 rounded-md w-full  my-2 bg-white">
                      <FaSearch className="ml-2" />{" "}
                      <input
                        placeholder={data?.searchBarTitle}
                        className="h-[31px] bg-transparent text-sm w-full px-2"
                        onChange={search}
                      />
                    </div>
                  </div>
                  <div className="border divide-y rounded-md">
                    <div className="px-6 py-2 bg-slate-100 flex justify-between">
                      <label className="text-sm font-bold w-1/5">
                        Worker Name
                      </label>
                      <label className="text-sm font-bold w-1/5">Email</label>
                      <label className="text-sm font-bold w-1/5">
                        Designation
                      </label>
                      <label className="text-sm font-bold w-1/5">
                        Select All
                      </label>
                    </div>
                    <div className="h-[50vh] w-full overflow-y-scroll scroll-display-none">
                      {data?.options?.map((item, index) => (
                        <div
                          className="px-6 py-2 hover:bg-slate-100 cursor-pointer flex justify-between items-center"
                          key={index}
                        >
                          {" "}
                          <label className="text-sm w-1/5 max-w-[150px] overflow-hidden text-ellipsis whitespace-nowrap">
                            <Tooltip title={item?.name}>{item?.name}</Tooltip>
                          </label>
                          <label className="text-sm w-1/5 max-w-[150px] overflow-hidden text-ellipsis whitespace-nowrap">
                            <Tooltip title={item?.email}>{item?.email}</Tooltip>
                          </label>
                          <label className="text-sm w-1/5 max-w-[150px] overflow-hidden text-ellipsis whitespace-nowrap">
                            <Tooltip
                              title={
                                item?.worker_details?.[0]?.worker_designation
                                  ?.name
                              }
                            >
                              {
                                item?.worker_details?.[0]?.worker_designation
                                  ?.name
                              }
                            </Tooltip>
                          </label>
                          <div className="w-1/5 flex justify-center">
                            <Checkbox
                              id={index}
                              key={index}
                              checked={data?.selectedOption?.includes(item?.id)}
                              onChange={(e) => {
                                handleMultySelect?.check(e, item?.id);
                                setSelectedWorker(item?.name);
                              }}
                            />
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>

                  <div className="">
                    <div className="pt-4">
                      <Button
                        className="float-right btn btn-blue w-1/5 bg-blue-disable"
                        onClick={addWorker}
                        disabled={data?.selectedOption == 0}
                      >
                        {isLoading ? <ButtonSpinner /> : data?.buttonTitle}
                      </Button>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      {inductionPending && (
        <div className="modal">
          <div className="modal-content">
            <div className="text-right">
              <button
                className="close-modal"
                onClick={() => setInductionPending(false)}
              >
                <FaTimes />
              </button>
            </div>

            {/* <div className="modal-body mt-2">
            <h2 className="text-xl font-bold mb-3">Induction in progress</h2>
            <p className="text-[#545454] text-[17px]">{selectedWorkerItem?.map((worker, i) => worker.name).join(', ')} is currently being inducted.</p>
            <p className="text-[red]">Creating this shift stop their induction, are you sure you to continue?</p>
            <div className="pt-4">
              <Button
                className="float-right btn btn-blue w-1/5 bg-blue-disable"
                onClick={overRideWorker}
              // disabled={data?.selectedOption == 0}
              >
                <FaTimes />
              </Button>
            </div> */}

            <div className="modal-body mt-2">
              <h2 className="text-xl font-bold mb-3">Induction in progress</h2>
              <p className="text-[#545454] text-[17px]">
                {selectedWorkerItem?.map((worker, i) => worker.name).join(", ")}{" "}
                is currently being inducted.
              </p>
              <p className="text-[red]">
                Creating this shift stop their induction, are you sure you to
                continue?
              </p>
              <div className="pt-4">
                <Button
                  className="float-right btn btn-blue w-1/5 bg-blue-disable"
                  onClick={overRideWorker}
                  // disabled={data?.selectedOption == 0}
                >
                  {isLoadingOverride ? <ButtonSpinner /> : "Continue"}
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}
      {payrollpending && (
        <div className="modal">
          <div className="modal-content">
            <div className="text-right">
              <button
                className="close-modal"
                onClick={() => setPayrollPending(false)}
              >
                <FaTimes />
              </button>
            </div>

            <div className="modal-body mt-2">
              <h2 className="text-xl font-bold mb-4">No Rates Available</h2>
              <p className="text-[#545454] text-[18px] mb-4">
                {selectedWorkerItem?.map((worker, i) => worker.name).join(", ")}{" "}
              </p>
              <p className="text-[#545454]">
                Before a shift can be created for Workers, you will need to
                assign at least one payroll category to Worksite
              </p>
              <div className="pt-4">
                <Button
                  className="float-right btn btn-blue w-1/5 bg-blue-disable"
                  onClick={() =>
                    openPayrollModal(
                      selectedWorkerItem?.map((worker, i) => worker._id)
                    )
                  }
                >
                  <FaTimes />
                </Button>
              </div>

              <div className="modal-body mt-2">
                <h2 className="text-xl font-bold mb-4">No Rates Available</h2>
                <p className="text-[#545454] text-[18px] mb-4">
                  {selectedWorkerItem
                    ?.map((worker, i) => worker.name)
                    .join(", ")}{" "}
                </p>
                <p className="text-[#545454]">
                  Before a shift can be created for Workers, you will need to
                  assign at least one payroll category to Worksite
                </p>
                <div className="pt-4">
                  <Button
                    className="float-right btn btn-blue w-1/5 bg-blue-disable"
                    onClick={() =>
                      openPayrollModal(
                        selectedWorkerItem?.map((worker, i) => worker._id)
                      )
                    }
                  >
                    {isLoadingOverride ? <ButtonSpinner /> : "Assign"}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {workDayPending && (
        <div className="modal">
          <div className="modal-content">
            <div className="text-right">
              <button
                className="close-modal"
                onClick={() => setWorkDayPending(false)}
              >
                <FaTimes />
              </button>
            </div>

            <div className="modal-body mt-2">
              <h2 className="text-xl font-bold mb-4">
                Select payroll category
              </h2>
              <table className="w-full">
                <tbody className="bg-white">
                  {payrollData?.data?.map((item, index) => (
                    <tr key={index} className="border-b border-[#F0F0F0]">
                      <td>{item?.name}</td>
                      <td>
                        <Checkbox onChange={(e) => checkPayroll(e, item)} />
                        {/* <input type="radio" name="payroll" onChange={(e) => checkPayroll(e, item)} /> */}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>

              <div className="pt-4">
                <Button
                  className="float-right btn btn-blue w-1/5 bg-blue-disable"
                  onClick={assignPayroll}
                  // disabled={selectedTask?.length == 0}
                >
                  {isLoadingOverride ? <ButtonSpinner /> : "Assign"}
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}
      {payrollModal && (
        <div className="modal payroll">
          <div className="modal-content">
            <div className="text-right">
              <button className="close-modal" onClick={closePayrollModal}>
                <FaTimes />
              </button>
            </div>

            <div className="modal-body mt-2">
              <h2 className="text-xl font-bold mb-4">
                At least one Fulltime and one Casual Payroll category should be
                chosen
              </h2>
              <table className="w-full">
                <tbody className="bg-white">
                  {payrollData?.data?.map((item, index) => (
                    <tr key={index} className="border-b border-[#F0F0F0]">
                      <td title="Payroll Category Name and Hourly Rate Name">
                        {item?.name} - {item?.hourly_rate_name}(
                        {item?.hourly_rate})
                      </td>
                      <td>
                        <Checkbox onChange={(e) => checkPayroll(e, item)} />
                        {/* <input type="radio" name="payroll" onChange={(e) => checkPayroll(e, item)} /> */}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>

              <div className="pt-4">
                <Button
                  className="float-right btn btn-blue w-1/8 bg-blue-disable"
                  onClick={assignPayroll}
                  // disabled={selectedTask?.length == 0}
                >
                  {isLoadingOverride ? <ButtonSpinner /> : "Assign"}
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}
      {empStatePending && (
        <div className="modal">
          <div className="modal-content">
            <div className="text-right">
              <button
                className="close-modal"
                onClick={() => setEmpDayPending(false)}
              >
                <FaTimes />
              </button>
            </div>

            <div className="modal-body mt-2">
              {/* <h2 className="text-xl font-bold mb-4">No {selectedWorkerItem?.map((worker, i) => worker.name).join(', ')} Available </h2> */}
              {selectedWorkerItem?.map((worker, i) => (
                <div key={i} className="mb-4">
                  <p className="text-[#545454] mb-2 text-[20px]">
                    {dataStore?.name ? dataStore?.name : dataStore?.jobName} -{" "}
                    {worker.name}
                  </p>
                  <p className="text-[#545454] mb-2">
                    Before a shift can be created for Workers, you will need to
                    assign following rates
                  </p>
                  <label className="pt-4 font-bold">
                    {worker?.employment_status}
                  </label>
                  <div className="flex justify-end">
                    {/* <Button
                    className="btn btn-blue w-1/5 bg-blue-disable"
                    onClick={() => { openPayrollModal(worker?._id); setSelectedWorkerId(worker?._id) }}
                    disabled={assignedWorker?.includes(worker?._id)}
                  >
                    {assignedWorker?.includes(worker?._id) ? 'Assigned' : 'Assign'}
                  </Button> */}
                    <Select
                      placeholder="Select payroll category"
                      className="w-full"
                      onChange={(e) =>
                        handleChangePayrollArr(e, i, worker?._id)
                      }
                      onClick={() =>
                        openPayrollCategory(
                          worker?.employment_status_id,
                          worker?._id
                        )
                      }
                    >
                      {payrollDataArr?.map((item) => (
                        <Select.Option value={item?.id}>
                          {item?.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </div>
                </div>
              ))}
              <div className="pt-4 flex justify-center">
                <Button
                  className="btn btn-blue w-1/5 bg-blue-disable"
                  onClick={assignPayrollWorker}
                >
                  Assign
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}
      {empStatus && (
        <div className="modal">
          <div className="modal-content">
            <div className="text-right">
              <button
                className="close-modal"
                onClick={() => setEmpStatus(false)}
              >
                <FaTimes />
              </button>
            </div>

            <div className="modal-body mt-2">
              <h2 className="text-xl font-bold mb-4">No Rates Available</h2>
              <div className="mb-4">
                <p className="text-[#545454] mb-2 text-[20px]">
                  {dataStore?.name ? dataStore?.name : dataStore?.jobName}
                </p>
                <p className="text-[#545454] mb-2">
                  Before a shift can be created for workers, you will need to
                  assign at least one Casual and Fulltime payroll category to
                  the worksite
                </p>
              </div>
              <div className="pt-4 flex justify-center">
                <Button
                  className="btn btn-blue w-1/8 bg-blue-disable"
                  onClick={openPayrollModal}
                >
                  Assign
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}
      {payrollPendingData && (
        <div className="modal">
          <div className="modal-content">
            <div className="text-right">
              <button
                className="close-modal"
                onClick={() => setPayrollPendingData(false)}
              >
                <FaTimes />
              </button>
            </div>

            <div className="modal-body mt-2">
              {selectedWorkerItem?.map((worker, i) => (
                <div key={i} className="mb-4">
                  <h2 className="text-xl font-bold mb-4">
                    Select {worker?.name}'s pay rate
                  </h2>
                  <p className="text-[#545454] mb-2">
                    This worker has not been assigned a pay rate on this
                    worksite. Please assign it now by selecting a rate below:
                  </p>
                  <div className="flex justify-end">
                    <Select
                      placeholder="Select payroll category"
                      className="w-full"
                      onChange={(e) =>
                        handleChangePayrollArr(e, i, worker?._id)
                      }
                      onClick={() =>
                        openPayrollCategory(
                          worker?.employment_status_id,
                          worker?._id
                        )
                      }
                    >
                      {payrollDataArr?.map((item) => {
                        if (
                          item?.employement_status_id ===
                          worker?.employment_status_id
                        ) {
                          return (
                            <Select.Option value={item?.id} key={item?.id}>
                              {item?.name}
                            </Select.Option>
                          );
                        }
                      })}
                    </Select>
                  </div>
                </div>
              ))}
              <div className="pt-4 flex justify-center">
                <Button
                  className="btn btn-blue w-1/5 bg-blue-disable"
                  onClick={assignPayrollWorkerPayroll}
                >
                  Assign
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default MultySelectTable;
