import React from "react";
import { Modal, Input, Tooltip, Select, Button } from "antd";
import { FaQuestionCircle } from "react-icons/fa";
import { useState, useEffect, useCallback } from "react";
import { GetWorkSite } from "../../../helper/worksite/worksite";
import { PayRollService } from "../../../config/axiosUrl";
import { UserService } from "../../../config/axiosUrl";
import { ButtonSpinner } from "../../../components/spinners";
import { SearchData } from "../../../helper/global/global";
import { CreateEditWorkers } from "../../../helper/personnel/workers";
import { toast } from "react-toastify";
const OthersettingModal = ({
  visible,
  onClose,
  data,
  setEmpStatusData,
  selectedData1,
  onUpdateButtonClick,
  EmpReportStatus,
  names,
  worksitenames,
}) => {
  const [filter, setFilter] = useState({
    worksites: [],
    payment: [],
    allWorksiteLoaded: false,
    selectedWorksite: "",
  });

  const [empCat, setEmpCat] = useState([]);
  const [workerDeisgnation, setWorkerDesignation] = useState([]);
  const [alive, setAlive] = useState(false);
  console.log("data?.employee_id", names);
  const getWorksites = useCallback(
    async (search = "") => {
      const worksite = await GetWorkSite({
        search,
        take: 20,
        cursor: filter.worksites[filter.worksites.length - 1]?.id || "",
      });
      setFilter((prev) => {
        const allWork = prev.worksites.concat(worksite.data.data);
        const ids = [];
        const filtered = [];
        allWork.forEach((item) => {
          if (!ids.includes(item.id)) {
            ids.push(item.id);
            filtered.push(item);
          }
        });

        return {
          ...prev,
          worksites: filtered,
          allWorksiteLoaded: worksite.extra.totalItems <= filtered.length,
        };
      });
    },
    [filter.worksites]
  );

  useEffect(() => {
    getWorksites();
    setAlive(false);
  }, [alive]);

  const EmpList = () => {
    PayRollService.get(
      `/api/v1/setting/payroll/get-employment-status-data?`
    ).then((res) => {
      setEmpCat(res?.data?.data?.data);
    });
  };

  const workerDeisgnationApi = () => {
    UserService.get(
      `/api/v1/personal/workers/worker-designation/get-worker-designation`
    ).then((res) => {
      setWorkerDesignation(res?.data?.data?.data);
    });
  };
  useEffect(() => {
    workerDeisgnationApi();
    EmpList();
  }, []);

  const initialFormData = {
    employment_status_id: "",
    worksite_id: worksitenames?._id,
    employee_id: data?.employee_id || "",
    name: data?.name || "",
    email: data?.email || "",
    worker_designation_id: data?.worker_designation?.[0]?._id || "",
    is_send_shift_receipts: data?.is_send_shift_receipts || "",
  };

  const [formData, setFormData] = useState(initialFormData);
  console.log("tye formafata", formData);
  useEffect(() => {
    if (visible && data) {
      // Update the form data when the modal is opened and data is available
      setFormData({
        ...initialFormData,
        employment_status_id: names?._id || "",
        worksite_id: worksitenames?._id || "",
        employee_id: selectedData1.employee_id || "",
        name: selectedData1.name || "",
        email: selectedData1?.email || "",
        worker_designation_id: data?.worker_designation?.[0]?._id,
        is_send_shift_receipts: data?.is_send_shift_receipts,
      });
    }
  }, [visible, data, selectedData1, names]);

  const handleModalClose = () => {
    createDelete();
    onUpdateButtonClick();
    onClose();

    // Make an API call after closing the modal
  };
  const createDelete = async () => {
    let params = {
      id: data.user_id,
      worker_details_id: data._id,
      name: formData.name,
      email: formData.email,
      employee_id: formData.employee_id,
      employment_status_id: formData.employment_status_id,
      work_site_id: formData.worksite_id,
      is_send_shift_receipts: formData.is_send_shift_receipts,
      worker_designation_id: formData.worker_designation_id,
      isSave: 0,
      password: "undefined",
      cpassword: "undefined",
      role: selectedData1.role,
      profile_image: "undefined",
    };
    console.log("the params is ", params);
    let getData = await CreateEditWorkers(params);

    if (getData?.status_code === 500) {
      toast.error(getData?.message);
    } else {
      EmpReportStatus();
      toast.success(getData?.message);
    }
  };

  return (
    <Modal
      title="Edit Workers"
      visible={visible}
      onCancel={onClose}
      footer={null}
    >
      <form className="mt-4">
        <div className="item">
          <label htmlFor="stat">Employement Status</label>
          <Select
            placeholder="Select Employement Status"
            optionFilterProp="children"
            className="mt-3  w-full"
            name="employment_status_id"
            value={formData.employment_status_id}
            onChange={(value) => {
              console.log("Selected Employment Status ID:", value);
              setFormData({ ...formData, employment_status_id: value });
            }}
          >
            {empCat?.map((item) => (
              <Select.Option key={item.id} value={item.id}>
                {item.name}
              </Select.Option>
            ))}
          </Select>
        </div>
        <div className="item mt-3">
          <label htmlFor="site">Worksite</label>

          <Select
            showSearch
            placeholder="Select Worksite"
            className="mt-3 w-full"
            name="worksite_id"
            value={formData.worksite_id}
            onChange={(value) =>
              setFormData({ ...formData, worksite_id: value })
            }
          >
            {filter.worksites.map((item) => (
              <Select.Option key={item.id} value={item._id}>
                {item.name}
              </Select.Option>
            ))}
          </Select>
        </div>

        <div className="item mt-3">
          <label htmlFor="employee_id">Employee Id</label>
          <Input
            type="text"
            id="employee_id"
            name="employee_id"
            value={formData.employee_id}
            onChange={(e) =>
              setFormData({ ...formData, employee_id: e.target.value })
            }
            autoComplete="off"
          />
        </div>

        <div className="item mt-3">
          <div className="flex justify-between items-center">
            <label htmlFor="name">Name</label>
            <Tooltip title="Enter worker name">
              <FaQuestionCircle className="fs-10" />
            </Tooltip>
          </div>
          <Input
            type="text"
            id="name"
            name="name"
            value={formData.name}
            onChange={(e) => setFormData({ ...formData, name: e.target.value })}
            autoComplete="off"
          />
        </div>
        <div className="item mt-3">
          <label>Email</label>
          <Input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={(e) =>
              setFormData({ ...formData, email: e.target.value })
            }
            autoComplete="off"
            required
          />

          <p className="fs-14 mt-1">
            *Workers without an email address will not be inducted{" "}
          </p>
        </div>
        <div className="item mt-3">
          <label htmlFor="site">Employment Designation</label>

          <Select
            showSearch
            placeholder="Select Employment Designation"
            className="mt-3 w-full"
            name="worker_designation_id"
            value={formData.worker_designation_id}
            onChange={(value) =>
              setFormData({ ...formData, worker_designation_id: value })
            }
          >
            {workerDeisgnation.map((item) => (
              <Select.Option key={item.id} value={item._id}>
                {item.name}
              </Select.Option>
            ))}
          </Select>
        </div>
        <div className="item mt-3">
          <label htmlFor="shift">Shift Receipts</label>
          <div className="flex justify-start items-center mt-3">
            <input
              type="checkbox"
              id="sh"
              name="is_send_shift_receipts"
              //   checked={Boolean(workers?.is_send_shift_receipts)}
              //   onChange={handleWorkers?.check}
              checked={formData.is_send_shift_receipts}
              onChange={(e) =>
                setFormData({
                  ...formData,
                  is_send_shift_receipts: e.target.checked ? 1 : 0,
                })
              }
            />
            <label htmlFor="sh" className="ml-2">
              Send Shift Receipts to Worker
            </label>
          </div>
        </div>
        <div className="item mt-3 text-center">
          <Button
            onClick={() => {
              // handleWorkers?.submit(); // Uncomment and add your submit logic here
              handleModalClose(); // Close the modal
            }}
            className="btn btn-blue w-1/5 bg-blue-disable"
          >
            Update
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default OthersettingModal;
